import React, { useState, useEffect, useContext } from 'react';
import {
  useLocation,
  Link,
  useParams,
  useSearchParams,
  useNavigate,
} from 'react-router-dom';
import { PlayIcon, DocumentIcon } from '@heroicons/react/24/outline';
import {
  PlayIcon as PlayActiveIcon,
  DocumentIcon as DocumentActiveIcon,
} from '@heroicons/react/24/solid';
import { Typography, Layout, MenuProps, Pagination } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import BreadcrumbContext from '../components/Context';
import DateDropdown from '../components/Header/DateDropdown';
import {
  defaultPageSize,
  filterVideoData,
  filterWhatsTrendingData,
} from '../helper/UtilityMethods';
import ResearchLayout from '../components/Layout/ResarchLayout';
import { getWhatsTrending } from '../services/ApiActions/whatsTrendingAct';

type MenuItem = Required<MenuProps>['items'][number];

const WhatsTrendingPage: React.FC = () => {
  const iconWidth = 17;
  const { addBreadcrumbitem } = useContext(BreadcrumbContext);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { reportName } = useParams();
  const defaultSelectedMenu = `submenu-${reportName}`;
  const [selectedDateOption, setSelectedDateOption] = useState('all');
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  //const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [tabsWithCount, setTabsWithCount] = useState([
    {
      label: 'PDF',
      key: 'pdf',
      count: 0,
      icon: <DocumentIcon width={iconWidth} />,
      icon_active: <DocumentActiveIcon width={iconWidth} />,
    },
    {
      label: 'Video',
      key: 'video',
      count: 0,
      icon: <PlayIcon width={iconWidth} />,
      icon_active: <PlayActiveIcon width={iconWidth} />,
    },
  ]);
  const defaultDate =
    searchParams.get('start-date') && searchParams.get('end-date')
      ? [
          dayjs(searchParams.get('start-date')),
          dayjs(searchParams.get('end-date')),
        ]
      : [];
  const [defaultDateRange, setDefaultDateRange] = useState<any>(defaultDate);
  const [selectedTab, setSelectedTab] = useState('pdf');
  const [selectedMenu, setSelectedMenu] = useState(defaultSelectedMenu);
  const [records, setRecords] = useState<any[]>([]);
  const [filteredRecords, setFilteredRecords] = useState<any[]>([]);
  const [menuItems, setMenuItems] = useState<MenuItem[]>([
    {
      key: 'submenu-founders-desk',
      label: (
        <Link
          to={`/whats-trending/founders-desk`}
          onClick={() => onLinkClick()}
        >
          Founder's Desk
        </Link>
      ),
    },
    {
      key: 'submenu-must-watch',
      label: (
        <Link to={`/whats-trending/must-watch`} onClick={() => onLinkClick()}>
          Brand Video
        </Link>
      ),
    },
    {
      key: 'submenu-corporate-video',
      label: (
        <Link
          to={`/whats-trending/corporate-video`}
          onClick={() => onLinkClick()}
        >
          Corporate Video
        </Link>
      ),
    },
    {
      key: 'submenu-highly-recommended',
      label: (
        <Link
          to={`/whats-trending/highly-recommended`}
          onClick={() => onLinkClick()}
        >
          Highly Recommended
        </Link>
      ),
    },
    {
      key: 'submenu-product-video',
      label: (
        <Link
          to={`/whats-trending/product-video`}
          onClick={() => onLinkClick()}
        >
          Product Video
        </Link>
      ),
    },
  ]);
  useEffect(() => {
    setSelectedTab('pdf');
    setSelectedDateOption('all');
  }, [reportName]);
  useEffect(() => {
    setIsLoading(true);
    const startDate = searchParams.get('start-date') || null || undefined;
    const endDate = searchParams.get('end-date') || null || undefined;
    const defaultDate =
      startDate && endDate ? [dayjs(startDate), dayjs(endDate)] : [];
    setDefaultDateRange(defaultDate);

    if (reportName) {
      getWhatsTrending(reportName, startDate, endDate, current, pageSize)
        .then((response: any) => {
          const recordList = filterWhatsTrendingData(response, selectedTab);
          console.log('total', recordList);
          setFilteredRecords(recordList);
          setRecords(response);
          setTotal(response.data.pagination.total);

          setSelectedMenu(`submenu-${reportName}`);
        })
        .catch((error: any) => {
          console.error(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [reportName, searchParams, current, pageSize]);
  const onLinkClick = () => {
    setSelectedTab('pdf');
    setSelectedDateOption('all');
  };
  const onRangeChange = (e: any) => {
    navigate({
      pathname: location.pathname,
      search: e
        ? `?start-date=${e[0].format('YYYY-MM-DD')}&end-date=${e[1].format(
            'YYYY-MM-DD'
          )}`
        : '',
    });
  };
  const onAllClick = () => {
    setSelectedDateOption('all');
    navigate({
      pathname: location.pathname,
      search: ``,
    });
  };
  const handleOnOptionChange = (value: string) => {
    setSelectedDateOption(value);
    setCurrent(1);
  };
  const onTabClick = (tabId: string) => {
    const recordList = filterWhatsTrendingData(records, tabId);

    setSelectedTab(tabId);
    setFilteredRecords(recordList);
  };
  const handlePageChange = (current: number) => {
    setCurrent(current);
  };
  const handleShowSizeChange = (current: number, size: number) => {
    setPageSize(size);
    setCurrent(current);
  };

  return (
    <>
      <ResearchLayout
        tabs={tabsWithCount}
        title="What's Trending"
        records={filteredRecords}
        selectedMenu={selectedMenu}
        menuItems={menuItems}
        isLoading={isLoading}
        defaultDateRange={defaultDateRange}
        onRangeChange={onRangeChange}
        onAllClick={onAllClick}
        selectedTab={selectedTab}
        onTabClick={onTabClick}
        showDateRange={true}
        pagination={
          total > 0 && (
            <Pagination
              pageSize={pageSize}
              onChange={handlePageChange}
              onShowSizeChange={handleShowSizeChange}
              showSizeChanger={true}
              current={current}
              total={total}
              className='fixed-pagination'
              style={{ display: 'flex', justifyContent: 'center' }}
            />
          )
        }
        selectedDateOption={selectedDateOption}
        // dateFilterComponent={
        //   <DateDropdown
        //     selectedOption={selectedDateOption}
        //     onOptionChange={handleOnOptionChange}
        //   />
        // }
      />
    </>
  );
};

export default WhatsTrendingPage;
