import React from 'react';
import { useParams } from 'react-router-dom';
import MustWatchPage from './MustWatchPage';
import RecentUpdatesPage from './RecentUpdatesPage';
const InformedInvestorPage = () => {
  const { updateType } = useParams();
  return updateType === 'highly-recommended' ? (
    <MustWatchPage />
  ) : (
    <RecentUpdatesPage />
  );
};

export default InformedInvestorPage;
