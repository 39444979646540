import ReactPlayer from 'react-player';
interface VideoPlayerProps {
  id: string;
  videoURL: string;
  playingVideoId: string | null;
  onVideoPlay: (isPlaying: string | null) => any;
}
const VideoPlayer: React.FC<VideoPlayerProps> = ({
  id,
  videoURL,
  playingVideoId,
  onVideoPlay,
}) => {
  return (
    <div className='player-wrapper'>
      {/* <div>{playingVideoId}</div> */}
      <ReactPlayer
        controls
        className='react-player'
        width='100%'
        height='100%'
        url={videoURL}
        playing={playingVideoId && id == playingVideoId ? true : false}
        onPlay={() => {
          onVideoPlay(id);
        }}
        onPause={() => {
          playingVideoId && id == playingVideoId && onVideoPlay(null);
        }}
        config={{
          file: {
            attributes: {
              controlsList: 'nodownload', // Disable download & playback speed
            },
          },
        }}
      />
    </div>
  );
};
export default VideoPlayer;
