import React from 'react';
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from 'react-router-dom';
import './App.scss';
import { ConfigProvider } from 'antd';
import ScenarioPage from './pages/Scenario/ScenarioPage';
import NewsletterPage from './pages/NewsletterPage';
import CommonLayout from './components/Layout';
import AdminLayout from './components/Layout/AdminLayout';
import ChatPage from './pages/chat';
import NotFoundPage from './pages/NotFoundPage';
import KnowledgeBase from './pages/KnowledgeBasePage';
import LoginPage from './pages/Login/LoginPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import { isLoggedIn } from './services/ApiActions/authAct';
import RecentUpdatesPage from './pages/RecentUpdatesPage';
import ResearchReportPage from './pages/ResearchReportPage';
import LearnPage from './pages/LearnPage';
import BestCallsPage from './pages/BestCalls/BestCallsPage';
import BestCallDetailsPage from './pages/BestCalls/BestCallDetailsPage';
import LMSPage from './pages/LMSPage';
import LMSCoursePage from './pages/LMSCoursePage';
import LMSVideoPage from './pages/LMSVideoPage';
import ProfileModal from './components/Profile/ProfileModal';
import ResetPassword from './components/Profile/ResetPassword';
import AdminPage from './pages/AdminPage';
import PeoplePage from './pages/PeoplePage';
import AdminNotification from './pages/AdminNotification';
import AdminAssessmentPage from './pages/AdminAssessmentPage';
import AssesmentPage from './pages/AssesmentPage';
import NotificationPage from './pages/NotificationPage';
import PasswordChangeSuccessPage from './pages/PasswordChangeSuccess';
import AchievementsPage from './pages/AchievementsPage';

import MCQReviewPage from './pages/Review/MCQReviewPage';
import NoModulesPage from './pages/Review/NoModule';
import WhatsTrendingPage from './pages/WhatsTrendingPage';
import InformedInvestorPage from './pages/InformedInvestorPage';
type AuthRouteProps = {
  component: React.ComponentType;
};

const AuthRoute = ({ component }: AuthRouteProps) => {
  let Component = component;
  if (!isLoggedIn()) {
    return <Navigate to='/login' />;
  }
  return <Component />;
};
/*{
            path: '*',
            element: <AuthRoute component={NotFoundPage} />, // Redirect to '/ask-me-anything' when visiting the root URL
          },*/
export const router = createBrowserRouter([
  {
    path: '/',
    element: <Navigate to='/login' />, // Redirect to '/login' when visiting the root URL
  },
  {
    path: '/login',
    element: <LoginPage />,
  },
  {
    path: '/password-change-success',
    element: <PasswordChangeSuccessPage />,
  },
  {
    path: '/forgot-password',
    element: <ForgotPasswordPage />,
  },
  {
    path: '/',
    element: <AdminLayout />,
    children: [
      { path: '/admin', element: <AuthRoute component={AdminPage} /> },
      {
        path: '/people/:userId/:selectedTab',
        element: <AuthRoute component={PeoplePage} />,
      },
      {
        path: '/admin-assessment',
        element: <AuthRoute component={AdminAssessmentPage} />,
      },

      {
        path: '/admin-notification',
        element: <AuthRoute component={AdminNotification} />,
      },
      {
        path: '/admin-quiz-review',
        element: <AuthRoute component={MCQReviewPage} />,
      },
      { path: '/no-module', element: <AuthRoute component={NoModulesPage} /> },
    ],
  },
  {
    path: '/',
    element: <CommonLayout fullHeight={true} />,
    children: [
      { path: '/no-module', element: <AuthRoute component={NoModulesPage} /> },
      {
        path: '/ask-me-anything',
        element: <AuthRoute component={ChatPage} />,
      },
      {
        path: '/ask-me-anything/:chatId',
        element: <AuthRoute component={ChatPage} />,
      },
      {
        path: '/scenarios/:scenarioId/:contentId',
        element: <AuthRoute component={ScenarioPage} />,
      },
    ],
  },
  {
    path: '/',
    element: <CommonLayout />,
    children: [
      {
        path: '/knowledge-base/:knowledgeBaseCategory',
        element: <AuthRoute component={KnowledgeBase} />,
      },
      {
        path: '/research-reports/:reportName',
        element: <AuthRoute component={ResearchReportPage} />,
      },
      {
        path: '/newsletter',
        element: <AuthRoute component={NewsletterPage} />,
      },
      {
        path: '/informed-investor-rr/:updateType',
        element: <AuthRoute component={InformedInvestorPage} />,
      },
      {
        path: '/learn',
        element: <AuthRoute component={LearnPage} />,
      },
      {
        path: '/best-calls',
        element: <AuthRoute component={BestCallsPage} />,
      },
      {
        path: '/best-calls/:departmentName',
        element: <AuthRoute component={BestCallDetailsPage} />,
      },
      {
        path: '/lms',
        element: <AuthRoute component={LMSPage} />,
      },
      {
        path: '/lms/course/:courseId',
        element: <AuthRoute component={LMSCoursePage} />,
      },
      {
        path: '/lms/course/:courseId/assesment/:assesmentId',
        element: <AuthRoute component={AssesmentPage} />,
      },
      {
        path: '/lms/course/:courseId/:moduleId/chapter',
        element: <AuthRoute component={LMSVideoPage} />,
      },
      {
        path: '/lms/course/:courseId/:moduleId/chapter/:chapterId',
        element: <AuthRoute component={LMSVideoPage} />,
      },
      {
        path: '/reset-password',
        element: <AuthRoute component={ResetPassword} />,
      },
      {
        path: '/profile-modal',
        element: <AuthRoute component={ProfileModal} />,
      },
      {
        path: '/user-notification',
        element: <AuthRoute component={NotificationPage} />,
      },
      {
        path: '/achievements',
        element: <AuthRoute component={AchievementsPage} />,
      },
      {
        path: '/whats-trending/:reportName',
        element: <AuthRoute component={WhatsTrendingPage} />,
      },
    ],
  },
]);
function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#001e5a',
          fontFamily: '"Open Sans", sans-serif',
          fontSize: 14,

          //colorInfoBg: "#fff",
          //colorInfo: "#fff",
        },

        components: {
          Table: {
            cellPaddingInline: 5,
            cellPaddingBlock: 10,
          },
          Layout: {
            //colorBgHeader: "#F5F7FA",
            bodyBg: 'transparent',
          },
          Avatar: {
            textFontSize: 16,
            textFontSizeLG: 16,
            textFontSizeSM: 16,
          },
          Menu: {
            itemSelectedBg: '#001e5a',
            itemSelectedColor: '#fff',
            //itemActiveBg: "#f00",
            subMenuItemBg: 'transparent',
            horizontalItemSelectedBg: 'green',
            //itemColor: "#ccc",
            /* here is your component tokens */
          },
          DatePicker: {
            /* here is your component tokens */
            activeBg: '#F0E2D5',
            activeBorderColor: '#9B6F49',
            cellActiveWithRangeBg: '#F0E2D5',
            cellHoverBg: '#9B6F49',
            hoverBorderColor: '#9B6F49',
            hoverBg: '#F0E2D5',
          },
          Progress: {
            /* here is your component tokens */
          },
          Collapse: {
            contentPadding: '4px 36px !important',
            /* here is your component tokens */
          },
        },
      }}
    >
      <RouterProvider router={router} />
    </ConfigProvider>
  );
}

export default App;
