import api from '../api';
import { scenarioApi, chatApi } from '../apiVariables';

const handleApiCall = (apiConfig: any) => {
  return new Promise((resolve, reject) => {
    api(apiConfig).then(resolve).catch(reject);
  });
};

export const getScenarioList = () => {
  const { getScenarioList: scenarioListConfig } = scenarioApi;
  return handleApiCall(scenarioListConfig);
};

export const getScenarioDetail = (scenarioId: string) => {
  const { getScenarioDetail: scenarioDetailConfig } = scenarioApi;
  const apiConfig = {
    ...scenarioDetailConfig,
    api: `/api/scenarios/${scenarioId}?populate[scenario_item][populate][videos]=*&populate[scenario_item][populate][audios]=*&populate[scenario_item][sort][0]=sort_order`,
  };
  return handleApiCall(apiConfig);
};

export const getQuestions = async (body: any, cancelToken: any) => {
  const { getQuestions: getQuestions } = chatApi;
  return await handleApiCall({ ...getQuestions, body, cancelToken });
};
export const getAnswer = async (body: any) => {
  const { getAnswer: answerConfig } = chatApi;
  return await handleApiCall({ ...answerConfig, body });
};
export const getAnswerByUUID = async (body: any) => {
  const { getAnswerByUUID: getAnswerByUUIDConfig } = chatApi;
  return await handleApiCall({ ...getAnswerByUUIDConfig, body });
};
export const getAllQuestions = async () => {
  const { getAllQuestions: getAllQuestionsConfig } = chatApi;
  return await handleApiCall({ ...getAllQuestionsConfig });
};
