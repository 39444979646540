import React, { useState, useRef } from 'react';
import ReactPlayer from 'react-player';
import { Slider, Button } from 'antd';
import {
  SpeakerWaveIcon,
  SpeakerXMarkIcon,
  PlayIcon,
  PauseIcon,
} from '@heroicons/react/24/outline';
interface AudioPlayerProps {
  url: string;
}
const CustomAudioPlayer: React.FC<AudioPlayerProps> = ({ url }) => {
  const playerRef = useRef<ReactPlayer>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [played, setPlayed] = useState(0); // Fraction (0 to 1)
  const [duration, setDuration] = useState(0); // In seconds
  const [currentTime, setCurrentTime] = useState(0); // In seconds
  const [volume, setVolume] = useState(1); // Volume (0 to 1)

  const togglePlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  const handleProgress = (state: { played: number; playedSeconds: number }) => {
    setPlayed(state.played);
    setCurrentTime(state.playedSeconds);
  };

  const handleDuration = (duration: number) => {
    setDuration(duration);
  };

  const handleSeek = (value: number) => {
    if (playerRef.current) {
      playerRef.current.seekTo(value / 100, 'fraction');
      setPlayed(value / 100);
    }
  };

  const formatTime = (seconds: number) => {
    const mins = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${mins}:${secs < 10 ? '0' : ''}${secs}`;
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: '10px',
        borderRadius: '50px',
        backgroundColor: '#f5f5f5',
        maxWidth: 600,
        margin: '0 auto',
      }}
    >
      {/* ReactPlayer */}
      <ReactPlayer
        ref={playerRef}
        url={url}
        playing={isPlaying}
        volume={volume}
        onProgress={handleProgress}
        onDuration={handleDuration}
        width='0'
        height='0'
      />

      {/* Play/Pause Button */}
      <Button
        type='text'
        shape='circle'
        icon={isPlaying ? <PauseIcon width={17} /> : <PlayIcon width={17} />}
        onClick={togglePlayPause}
      />

      {/* Time Display */}
      <div
        style={{ marginLeft: 10, marginRight: 10, fontSize: 14, color: '#000' }}
      >
        {formatTime(currentTime)} / {formatTime(duration)}
      </div>

      {/* Seek Bar */}
      <Slider
        value={played * 100} // Convert fraction to percentage
        onChange={handleSeek}
        style={{ flex: 1, marginRight: 10 }}
      />

      {/* More Options */}
      <Button
        type='text'
        shape='circle'
        icon={
          volume > 0 ? (
            <SpeakerWaveIcon
              width={17}
              onClick={() => setVolume(volume === 1 ? 0 : 1)}
            />
          ) : (
            <SpeakerXMarkIcon
              width={17}
              onClick={() => setVolume(volume === 1 ? 0 : 1)}
            />
          )
        }
      />
    </div>
  );
};

export default CustomAudioPlayer;
