import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import type { MenuProps } from 'antd';
import { PlayIcon, SpeakerWaveIcon } from '@heroicons/react/24/outline';
import {
  Typography,
  Layout,
  Menu,
  Breadcrumb as AntBreadcrumb,
  Flex,
  Dropdown,
  Space,
  Modal,
} from 'antd';
import { Link, useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import DOMpurify from 'dompurify';
import Loader from '../../components/Layout/Loader';
import BreadcrumbContext from '../../components/Context';
import { getScenarioDetail } from '../../services/ApiActions/scenarioAct';
import { MenuListType } from '../../components/Layout/Sidebar/Sidebar';
import { Content } from 'antd/es/layout/layout';
import PageHeading from '../../components/Layout/PageHeading';
import VideoPlayer from '../../components/VideoPlayer/VideoPlayer';
import CustomAudioPlayer from '../../components/AudioPlayer/AudioPlayer';
const { Sider } = Layout;
const { Title } = Typography;

const layoutCSS = {
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

type MenuItem = Required<MenuProps>['items'][number];
type contentDetails = {
  title: string;
  description: string;
  content: string;
  scenarioTitle?: string;
  pageHeading?: string;
  pageDescription?: string;
  scenarioSubTitle?: string;
  videos: any;
  audios: any;
};

const ScenarioPage = () => {
  const { scenarioId, contentId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [contentDetails, setContentDetails] = useState<contentDetails>();
  const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
  const { addBreadcrumbitem } = useContext(BreadcrumbContext);
  const location = useLocation();
  const locationFragment = location.pathname.split('/');
  const defaultSelectedMenu = `submenu-${scenarioId}-${contentId}`;
  const [selectedMenu, setSelectedMenu] = useState(defaultSelectedMenu);
  const [open, setOpen] = useState(false);
  const [mediaURL, setMediaURL] = useState('');
  const [videoId, setVideoId] = useState('');
  const [mediaTitle, setMediaTitle] = useState('');
  const [playingVideoId, setPlayingVideoId] = useState<string | null>(null);
  const [isVideo, setIsVideo] = useState(false);
  const getMediaItems = (medias: any, isVid: boolean) => {
    const mediaList =
      medias.length > 0
        ? medias.map((item: any) => {
            return {
              key: item.id,
              label: (
                <div onClick={() => toggleModal(item, isVid)}>{item.name}</div>
              ),
            };
          })
        : [
            {
              key: 'no items',
              label: (
                <div>{isVid ? 'No videos found' : 'No audios found'} </div>
              ),
            },
          ];
    return mediaList;
  };
  const toggleModal = (item: any, isVid: boolean) => {
    const { id, name, url } = item;
    setVideoId(id);
    setOpen(true);
    setIsVideo(isVid || false);
    setMediaURL(url);
    setMediaTitle(name);
  };
  const PageHeader: React.FC = () => {
    const videoList =
      (contentDetails?.videos && getMediaItems(contentDetails.videos, true)) ||
      [];
    const audioList =
      (contentDetails?.audios && getMediaItems(contentDetails.audios, false)) ||
      [];
    return (
      <PageHeading
        heading={contentDetails?.scenarioTitle || ''}
        rightContent={
          <Space className='item-center' align='center'>
            <Dropdown
              menu={{ items: videoList }}
              overlayClassName='scenario-media'
            >
              <span
                className='item-center'
                style={{
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '5px',
                }}
              >
                <PlayIcon width={24} />{' '}
                <span style={{ fontSize: '14px' }}>Video</span>
              </span>
            </Dropdown>
            |
            <Dropdown
              menu={{ items: audioList }}
              overlayClassName='scenario-media'
            >
              <span
                className='item-center'
                style={{
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '5px',
                }}
              >
                <SpeakerWaveIcon width={24} />{' '}
                <span style={{ fontSize: '14px' }}>Audio</span>
              </span>
            </Dropdown>
          </Space>
        }
        subHeading={
          <Flex
            className='scenario-sub-heading'
            align='center'
            style={{ marginLeft: '14px' }}
          >
            <Flex gap='small' align='baseline'>
              {/* <Title level={4} style={{ margin: '0' }}>
                test
                {contentDetails?.pageHeading}
              </Title> */}
              <Typography
                style={{ fontSize: '18px', color: 'rgba(0, 0, 0, 0.60)' }}
              >
                {contentDetails?.scenarioSubTitle}
              </Typography>
            </Flex>
            <div style={{ fontSize: '18px', color: 'rgba(0, 0, 0, 0.60)' }}>
              {contentDetails?.pageDescription}
            </div>
          </Flex>
        }
      />
    );
  };

  useEffect(() => {
    addBreadcrumbitem({
      breadCrumb: [
        { label: `Spoon Feed`, url: '' },
        { label: `Scenario`, url: location.pathname },
        contentDetails && {
          label: `${contentDetails?.scenarioTitle}`,
          url: location.pathname,
        },
        contentDetails && {
          label: `${contentDetails?.title}`,
          url: location.pathname,
        },
      ],
      headingInfo: {
        title: contentDetails?.pageHeading,
        description: contentDetails?.pageDescription,
      },
    });
  }, [contentDetails]);

  useEffect(() => {
    setIsLoading(true);
    scenarioId &&
      getScenarioDetail(scenarioId)
        .then((response: any) => {
          const content = response.data.attributes.scenario_item.find(
            (i: { id: string }) => i.id == contentId
          );
          const items: MenuItem[] = response.data.attributes.scenario_item.map(
            (item: MenuListType, index: number) => {
              const key = `submenu-${locationFragment[2]}-${item.id}`;
              return {
                key: key,
                label: (
                  <Link to={`/scenarios/${scenarioId}/${item.id}`}>
                    {item.title}
                  </Link>
                ),
              };
            }
          );
          setContentDetails({
            ...content,
            scenarioTitle: response.data.attributes.title,
            scenarioSubTitle: response.data.attributes.sub_heading,
            pageHeading: response.data.attributes.page_heading,
            pageDescription: response.data.attributes.page_description,
          });
          setMenuItems(items);
          setIsLoading(false);
          setSelectedMenu(`submenu-${scenarioId}-${contentId}`);
        })
        .catch((error) => {
          setIsLoading(false);
        });
  }, [scenarioId, contentId]);
  return (
    <>
      <Layout style={{ padding: '0', height: '100%' }}>
        <PageHeader />
        <Layout>
          <Sider
            width={220}
            style={{
              backgroundColor: 'transparent',
              //maxHeight: 'calc(100vh - 60px)',
              overflow: 'auto',
              marginBottom: '20px',
            }}
          >
            <Menu
              key={Math.random()}
              className='secondary-menu'
              defaultSelectedKeys={[selectedMenu]}
              defaultOpenKeys={[]}
              style={{
                backgroundColor: 'transparent',
                height: '100%',
                borderRight: 0,
              }}
              items={menuItems}
            />
          </Sider>
          <Content
            className='main-content scenario-container'
            style={{
              //height: 'calc(100vh - 52px)',
              //border: 'solid 1px #ccc',
              borderBottom: 'none',
              marginLeft: '20px',
              width: '100%',
              //height: 'calc(100vh - 90px)',
              overflow: 'hidden',
              //maxWidth: '900px',
              padding: '36px',
              background: '#fff',
              borderTopRightRadius: '15px',
              borderTopLeftRadius: '15px',
              //boxShadow: '4px 0px 10px 0px #ded8c400',
              //borderImageSource:'linear-gradient(180deg, #B5CAE7 0%, rgba(181, 202, 231, 0) 100%)',
            }}
          >
            {isLoading ? (
              <Loader style={{ marginTop: '20%', justifyContent: 'center' }} />
            ) : (
              <div
                style={{
                  height: '100%',
                  overflow: 'auto',
                  paddingRight: '20px',
                }}
              >
                <Title
                  className='scenario-heading'
                  level={4}
                  style={{ marginTop: 0 }}
                >
                  {contentDetails?.title}
                </Title>
                <div style={{ fontWeight: '500' }}>
                  <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                    {contentDetails?.content &&
                      DOMpurify.sanitize(contentDetails.content)}
                  </ReactMarkdown>
                </div>
              </div>
            )}
            <Modal
              title={mediaTitle}
              footer={null}
              centered
              open={open}
              onCancel={() => {
                setOpen(false);
              }}
              width={800}
              destroyOnClose
            >
              {isVideo ? (
                <VideoPlayer
                  id={videoId}
                  playingVideoId={playingVideoId}
                  onVideoPlay={(id) => setPlayingVideoId(id)}
                  videoURL={mediaURL}
                />
              ) : (
                <CustomAudioPlayer url={mediaURL} />
              )}
            </Modal>
          </Content>
        </Layout>
      </Layout>
    </>
  );
};

export default ScenarioPage;
