import api from '../api';
import { bestCallApi } from '../apiVariables';

const handleApiCall = (apiConfig: any) => {
  return new Promise((resolve, reject) => {
    api(apiConfig).then(resolve).catch(reject);
  });
};
export const getCallDepartments = () => {
  const { getCallDepartmentList: bestCallListConfig } = bestCallApi;
  return handleApiCall(bestCallListConfig);
};
//
export const getCallsByDepartment = (body: any) => {
  const { getCallList: bestCallListConfig } = bestCallApi;
  const apiConfig = { ...bestCallListConfig, body };
  return handleApiCall(apiConfig);
};
export const getKeyNotesByDepartment = (data: any) => {
  const { department_name } = data;
  let api = '/api/spoonfeed/get-best-calls-key-notes';
  if (department_name) {
    api += `?department_name=${department_name}`;
  }
  const { getKeyNoteList: keyNoteListConfig } = bestCallApi;
  const apiConfig = { ...keyNoteListConfig, api: api };
  return handleApiCall(apiConfig);
};
export const searchCalls = (data: any, cancelToken: any) => {
  const { searchTerm, departmentName } = data;
  const { searchCall: searchCallConfig } = bestCallApi;
  const apiConfig = {
    ...searchCallConfig,
    ...{
      api: `/api/spoonfeed/get-best-calls-by-key-note`,
    },
  };
  return handleApiCall({
    ...apiConfig,
    cancelToken,
    body: { search_term: searchTerm, department_name: departmentName },
  });
};
export const getCallById = (body: any) => {
  const { getCallById: bestCallListConfig } = bestCallApi;
  const apiConfig = { ...bestCallListConfig, body };
  return handleApiCall(apiConfig);
};
export const updateElapsedTime = (body: any) => {
  const { updateTime: updateTimeConfig } = bestCallApi;
  const apiConfig = { ...updateTimeConfig, body };
  return handleApiCall(apiConfig);
};
