import React, { useState, useEffect } from 'react';
import {
  useLocation,
  Link,
  useParams,
  useSearchParams,
  useNavigate,
} from 'react-router-dom';

import { Pagination } from 'antd';
import dayjs from 'dayjs';
import {
  defaultPageSize,
  filterWhatsTrendingData,
  informedTnvestorSubmenu,
} from '../helper/UtilityMethods';
import ResearchLayout from '../components/Layout/ResarchLayout';
import { getRecentUpdate } from '../services/ApiActions/newsletterAct';

const MustWatchPage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { reportName } = useParams();
  const defaultSelectedMenu = `submenu-highly-recommended`;
  const [selectedDateOption, setSelectedDateOption] = useState('all');
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  //const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const defaultDate =
    searchParams.get('start-date') && searchParams.get('end-date')
      ? [
          dayjs(searchParams.get('start-date')),
          dayjs(searchParams.get('end-date')),
        ]
      : [];
  const [defaultDateRange, setDefaultDateRange] = useState<any>(defaultDate);
  const [selectedTab, setSelectedTab] = useState('video');
  const [selectedMenu, setSelectedMenu] = useState(defaultSelectedMenu);
  const [records, setRecords] = useState<any[]>([]);
  const [filteredRecords, setFilteredRecords] = useState<any[]>([]);
  const menuItems = informedTnvestorSubmenu.map((item) => {
    return {
      key: `submenu-${item.key}`,
      label: (
        <Link
          to={`/informed-investor-rr/${item.key}`}
          onClick={() => onLinkClick()}
        >
          {item.label}
        </Link>
      ),
    };
  });
  useEffect(() => {
    setSelectedTab('video');
    setSelectedDateOption('all');
  }, [reportName]);
  useEffect(() => {
    setIsLoading(true);
    const startDate = searchParams.get('start-date') || null || undefined;
    const endDate = searchParams.get('end-date') || null || undefined;
    const defaultDate =
      startDate && endDate ? [dayjs(startDate), dayjs(endDate)] : [];
    setDefaultDateRange(defaultDate);
    getRecentUpdate(
      'highly-recommended',
      startDate,
      endDate,
      undefined,
      current,
      pageSize
    )
      .then((response: any) => {
        //const recordList = filterWhatsTrendingData(response, selectedTab);
        //console.log('total', recordList);
        setFilteredRecords(response.data);
        setRecords(response);
        setTotal(response.pagination.total);

        //setSelectedMenu(`submenu-${reportName}`);
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [reportName, searchParams, current, pageSize]);
  const onLinkClick = () => {
    setSelectedDateOption('all');
  };
  const onRangeChange = (e: any) => {
    navigate({
      pathname: location.pathname,
      search: e
        ? `?start-date=${e[0].format('YYYY-MM-DD')}&end-date=${e[1].format(
            'YYYY-MM-DD'
          )}`
        : '',
    });
  };
  const onAllClick = () => {
    setSelectedDateOption('all');
    navigate({
      pathname: location.pathname,
      search: ``,
    });
  };
  const onTabClick = (tabId: string) => {
    const recordList = filterWhatsTrendingData(records, tabId);

    setSelectedTab(tabId);
    setFilteredRecords(recordList);
  };
  const handlePageChange = (current: number) => {
    setCurrent(current);
  };
  const handleShowSizeChange = (current: number, size: number) => {
    setPageSize(size);
    setCurrent(current);
  };

  return (
    <>
      <ResearchLayout
        headerPixels='150px'
        tabs={[]}
        title='Informed InvestoRR'
        records={filteredRecords}
        selectedMenu={selectedMenu}
        menuItems={menuItems}
        isLoading={isLoading}
        defaultDateRange={defaultDateRange}
        onRangeChange={onRangeChange}
        onAllClick={onAllClick}
        selectedTab={selectedTab}
        onTabClick={onTabClick}
        showDateRange={true}
        pagination={
          total > 0 && (
            <Pagination
              pageSize={pageSize}
              onChange={handlePageChange}
              onShowSizeChange={handleShowSizeChange}
              showSizeChanger={true}
              current={current}
              total={total}
              className='fixed-pagination'
              style={{ display: 'flex', justifyContent: 'center' }}
            />
          )
        }
        selectedDateOption={selectedDateOption}
      />
    </>
  );
};

export default MustWatchPage;
