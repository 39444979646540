import { getCallById } from './ApiActions/bestCallAct';
import {
  getAchievements,
  getAllCourses,
  getAssesmentQuestions,
  getCertificate,
  saveQuestionResponse,
  submitQuizAnswers,
} from './ApiActions/lmsAct';
import { ApiRequestType } from './api';

export const newsletterApi = {
  getNewsletters: {
    api: '/api/custom/news-letter/activenews',
    method: 'get' as ApiRequestType,
  },
  getNewsletterById: {
    api: '/api/custom/news-letter/activenews',
    method: 'get' as ApiRequestType,
  },
};

export const bestCallApi = {
  getCallDepartmentList: {
    api: '/api/equentis/best-calls',
    method: 'get' as ApiRequestType,
    baseurl: 'cmsbaseurl',
  },
  getKeyNoteList: {
    api: '/api/spoonfeed/get-best-calls-key-notes',
    method: 'get' as ApiRequestType,
    baseurl: 'cmsbaseurl',
  },
  getCallList: {
    api: '/api/equentis/best-call-playlist',
    method: 'post' as ApiRequestType,
    baseurl: 'cmsbaseurl',
  },
  searchCall: {
    api: '/api/spoonfeed/get-best-calls-by-key-note',
    method: 'post' as ApiRequestType,
    baseurl: 'cmsbaseurl',
  },
  getCallById: {
    api: '/api/equentis/get_audio_detail',
    method: 'post' as ApiRequestType,
    baseurl: 'cmsbaseurl',
  },
  updateTime: {
    api: '/api/equentis/update-time-elapsed-playlist',
    method: 'post' as ApiRequestType,
    baseurl: 'cmsbaseurl',
  },
};
export const lmsApi = {
  getModuleList: {
    api: '/api/equentis/best-call-playlist',
    method: 'get' as ApiRequestType,
    baseurl: 'cmsbaseurl',
  },
  getAllCourses: {
    api: '/api/custom/all/learning-management-system',
    method: 'get' as ApiRequestType,
    baseurl: 'cmsbaseurl',
  },
  getCourseById: {
    api: '/api/custom/all/learning-management-system/courses',
    method: 'get' as ApiRequestType,
    baseurl: 'cmsbaseurl',
  },
  updateTime: {
    api: '/api/spoonfeed/lms/update-chapter-time-stamp',
    method: 'post' as ApiRequestType,
    baseurl: 'cmsbaseurl',
  },
  submitQuizAnswers: {
    api: '/api/spoonfeed/lms/quiz-questions/quiz-reponse',
    method: 'post' as ApiRequestType,
    baseurl: 'cmsbaseurl',
  },
  lastVideoInfo: {
    api: '/api/custom/all/learning-management-system/lastvideo',
    method: 'get' as ApiRequestType,
    baseurl: 'cmsbaseurl',
  },
  videoDetailsInfo: {
    api: '/api/custom/all/learning-management-system/videodetails',
    method: 'get' as ApiRequestType,
    baseurl: 'cmsbaseurl',
  },
  getQuestionByVideoId: {
    api: '/api/custom/all/learning-management-system/quiz-questions',
    method: 'get' as ApiRequestType,
    baseurl: 'cmsbaseurl',
  },
  saveQuestionResponse: {
    api: '/api/custom/all/learning-management-system/updatequestion',
    method: 'post' as ApiRequestType,
    baseurl: 'cmsbaseurl',
  },
  saveAssesmentResponse: {
    api: '/api/spoonfeed/lms/assessment/submit-assessment-response',
    method: 'post' as ApiRequestType,
    baseurl: 'cmsbaseurl',
  },
  getAssesmentQuestions: {
    api: '/api/spoonfeed/lms/assessment/assessment-questions',
    method: 'get' as ApiRequestType,
    baseurl: 'cmsbaseurl',
  },
  getCertificate: {
    api: '/api/spoonfeed/lms/generate-certificate/course',
    method: 'get' as ApiRequestType,
    baseurl: 'cmsbaseurl',
    responseType: 'arraybuffer',
  },
  getAchievements: {
    api: '/api/spoonfeed/lms/user-achievements',
    method: 'get' as ApiRequestType,
    baseurl: 'cmsbaseurl',
  },
};
//api: "/api/custom/scenarios",
export const scenarioApi = {
  getScenarioList: {
    api: '/api/scenarios?populate[scenario_item][sort][0]=sort_order&populate[scenario_item][fields]=title,content&sort[0]=sort_order',
    method: 'get' as ApiRequestType,
    baseurl: 'cmsbaseurl',
  },
  getScenarioDetail: {
    api: '/api/scenarios/1?populate[scenario_item][sort][0]=sort_order&populate[scenario_item][fields]=title,content',
    method: 'get' as ApiRequestType,
    baseurl: 'cmsbaseurl',
  },
};
export const knowledgeBaseApi = {
  getKnowledgeBaseCategories: {
    api: '/api/equentis/get_knowledge_base_category',
    method: 'get' as ApiRequestType,
    baseurl: 'cmsbaseurl',
  },
  getKnowledgeBaseDetails: {
    api: '/api/equentis/get_knowledge_base_topics',
    method: 'get' as ApiRequestType,
    baseurl: 'cmsbaseurl',
  },
};

export const chatApi = {
  getQuestions: {
    api: '/api/equentis/auto-complete-questions',
    method: 'post' as ApiRequestType,
    baseurl: 'baseurl',
  },
  getAnswer: {
    api: '/api/equentis/answer',
    method: 'post' as ApiRequestType,
    baseurl: 'baseurl',
  },
  getAnswerByUUID: {
    api: '/api/equentis/auto-complete-answer',
    method: 'post' as ApiRequestType,
    baseurl: 'baseurl',
  },
  getVersions: {
    api: '/api/equentis/versions',
    method: 'get' as ApiRequestType,
    baseurl: 'baseurl',
  },
  getAllQuestions: {
    api: '/api/equentis/get_knowledge_base',
    method: 'get' as ApiRequestType,
    baseurl: 'baseurl',
  },
};
export const researchReportApi = {
  getResearchParameters: {
    api: '/api/custom/researchparameters',
    method: 'get' as ApiRequestType,
    baseurl: 'baseurl',
  },
};

export const whatsTrendingApi = {
  getTrendingParameters: {
    api: '/api/tranding/founders-desk',
    method: 'get' as ApiRequestType,
    baseurl: 'baseurl',
  },
};

export const changePasswordApi = {
  getResearchParameters: {
    api: '/api/custom/changepassword',
    method: 'post' as ApiRequestType,
    baseurl: 'baseurl',
  },
};
export const authApi = {
  login: {
    api: '/api/auth/local',
    method: 'post' as ApiRequestType,
    baseurl: 'baseurl',
    isPublic: true,
  },
  forgetPassword: {
    api: '/api/auth/forgot-password',
    method: 'post' as ApiRequestType,
    baseurl: 'baseurl',
    isPublic: true,
  },
  resetPassword: {
    ///api/auth/change-password was giving error
    api: '/api/spoonfeed/change-password',
    method: 'post' as ApiRequestType,
    baseurl: 'baseurl',
    isPublic: false,
  },
  userRole: {
    api: '/api/spoonfeed/lms/user-department',
    method: 'get' as ApiRequestType,
    baseurl: 'baseurl',
    isPublic: false,
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    },
  },
  getUsers: {
    api: '/api/users',
    method: 'get' as ApiRequestType,
    baseurl: 'baseurl',
  },
};

export const adminPanelApi = {
  courseCompletionRate: {
    api: '/api/spoonfeed/lms/course-completion-rate',
    method: 'get' as ApiRequestType,
    baseurl: 'baseurl',
  },
  assessmentReview: {
    api: '/api/spoonfeed/lms/assessment-review',
    method: 'get' as ApiRequestType,
    baseurl: 'baseurl',
  },
  courseCompletionList: {
    api: '/api/spoonfeed/lms/course-completion-list',
    method: 'get' as ApiRequestType,
    baseurl: 'baseurl',
  },
  attemptedAssessment: {
    api: '/api/spoonfeed/lms/course-completion-rate',
    method: 'get' as ApiRequestType,
    baseurl: 'baseurl',
  },
  assessmentStatus: {
    api: '/api/spoonfeed/lms/course-completion-rate',
    method: 'get' as ApiRequestType,
    baseurl: 'baseurl',
  },
  modulePercentage: {
    api: '/api/spoonfeed/lms/course-completion-rate',
    method: 'get' as ApiRequestType,
    baseurl: 'baseurl',
  },
  reviewAssessmentforCourse: {
    api: '/api/spoonfeed/lms/course-completion-rate',
    method: 'get' as ApiRequestType,
    baseurl: 'baseurl',
  },
  assessmentResult: {
    api: '/api/spoonfeed/lms/course-completion-rate',
    method: 'get' as ApiRequestType,
    baseurl: 'baseurl',
  },
  quizResult: {
    api: '/api/spoonfeed/lms/course-completion-rate',
    method: 'get' as ApiRequestType,
    baseurl: 'baseurl',
  },
  adminAssessmentResult: {
    api: '/api/spoonfeed/lms/course-completion-rate',
    method: 'post' as ApiRequestType,
    baseurl: 'baseurl',
  },
  userDepartment: {
    api: '/api/user-departments',
    method: 'get' as ApiRequestType,
    baseurl: 'baseurl',
  },
  courses: {
    api: '/api/spoonfeed/lms/all-courses-for-admin',
    method: 'get' as ApiRequestType,
    baseurl: 'baseurl',
  },
  modules: {
    api: '/api/spoonfeed/lms/modules-for-admin',
    method: 'get' as ApiRequestType,
    baseurl: 'baseurl',
  },
  chapters: {
    api: '/spoonfeed/lms/chapters-for-admin',
    method: 'get' as ApiRequestType,
    baseurl: 'baseurl',
  },
  createInitialProgress: {
    api: '/api/spoonfeed/lms/create-initial-progress',
    method: 'get' as ApiRequestType,
    baseurl: 'baseurl',
  },
};

export const reviewApi = {
  getQuizQuestions: {
    api: `/spoonfeed/admin/quiz`,
    method: 'get' as ApiRequestType,
    baseurl: 'baseurl',
  },
  getAssesmentQuestions: {
    api: `/spoonfeed/admin/assessment`,
    method: 'get' as ApiRequestType,
    baseurl: 'baseurl',
  },
  assessmentResultByAdmin: {
    api: `/api/spoonfeed/lms/assessment-result/assessment-result-by-admin`,
    method: 'post' as ApiRequestType,
    baseurl: 'baseurl',
  },
};

export const peopleApi = {
  getAssessmentStatus: {
    api: `/api/spoonfeed/lms/assessment-status`,
    method: 'get' as ApiRequestType,
    baseurl: 'baseurl',
  },
  getCourseStatus: {
    api: `/api/spoonfeed/lms/assessment-status`,
    method: 'get' as ApiRequestType,
    baseurl: 'baseurl',
  },
  assessmentAttempted: {
    api: `/api/spoonfeed/lms/attempted-assessment`,
    method: 'get' as ApiRequestType,
    baseurl: 'baseurl',
  },
};
export const notificationApi = {
  userNotification: {
    api: '/api/spoonfeed/lms/user-notification',
    method: 'get' as ApiRequestType,
    baseurl: 'baseurl',
  },
  adminNotification: {
    api: 'api/spoonfeed/lms/admin-notification',
    method: 'get' as ApiRequestType,
    baseurl: 'baseurl',
  },
};
